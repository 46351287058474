import { useMutation } from 'react-query';
import { ControlApi } from '@vgs-console/api';

export const useControlApi = () => {
  const enableControlService = useMutation((orgId: string) => ControlApi.createOrganization(orgId));

  return {
    enableControlService,
  };
};

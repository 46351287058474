import { useMutation, useQuery } from 'react-query';
import { PersonalizationApi } from '@vgs-console/api';

export const usePersonalizationApi = (userId: string) => {
  const userProfile = useQuery(['profiles', userId], () =>
    PersonalizationApi.getUserProfile(userId),
  );

  const updateUserProfile = useMutation(
    (data: { userId: string; settings: { [key: string]: string } }) => {
      const { userId, ...payload } = data;

      return PersonalizationApi.updateUserProfile(userId, payload);
    },
  );

  return {
    userProfile,
    updateUserProfile,
  };
};

import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import {
  Card,
  Icon,
  Input,
  Modal,
  Tile,
  Select,
  Popover,
  Button,
  Tooltip,
  useHeapAnalytics,
  HubspotPersonalizationFormRef,
  useGoogleTagManager,
  notification,
} from '@vgs/elemente';
import AuthService from '@vgs-console/services/AuthService';
import { PersonalizationApi } from '@vgs-console/api';
import {
  HubspotPersonalizationForm,
  PERSONALIZATION_FORM_FIELDS,
  PersonalizationFormValues,
} from '@vgs-console/components/templates/hubspot-forms';
import config from '@vgs-config';
import { useAccountsApi, useControlApi, useOrganizationStatus } from '@vgs-console/hooks';
import { USE_CASE_CATEGORIES, UseCaseCategoryValues } from './use-case-categories';
import { USE_CASE_CATEGORY_DESCRIPTION } from './category-description';

const { Option } = Select;

enum FlowCompleteEvent {
  REDIRECT_TO = 'Redirect to',
  USE_CASE_CATEGORY = 'Use case category',
  USE_CASE_DETAILS = 'Use case details',
}

const FLOW_COMPLETE_EVENT = 'Console Signup Complete';
const TOOLTIP_REQUIRED_FIELDS = 'Please fill all fields';
const ORG_STATUS_POLLING_INTERVAL = 1000;

const WelcomeWidget = () => {
  const [isProvisioning, setIsProvisioning] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [organizationName, setOrganizationName] = useState<string>();
  const [organizationId, setOrganizationId] = useState<string>('');
  const [isOrganizationProvisioned, setIsOrganizationProvisioned] = useState<boolean>(false);
  const [category, setCategory] = useState<UseCaseCategoryValues>();
  const [primaryUseCase, setPrimaryUseCase] = useState<string>('');
  const [personalizationForm, setPersonalizationForm] = useState<PersonalizationFormValues>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const hubspotFormRef = useRef<HubspotPersonalizationFormRef>(null);
  const { createOrganization, updateOrganization } = useAccountsApi();
  const { data: organizationStatus } = useOrganizationStatus(organizationId, {
    enabled: !!organizationId,
    refetchInterval: !isOrganizationProvisioned && ORG_STATUS_POLLING_INTERVAL,
  });
  const { enableControlService } = useControlApi();

  const heapAnalytics = useHeapAnalytics();
  const gtm = useGoogleTagManager();

  const hasPrimaryUseCases = category && !!USE_CASE_CATEGORIES[category].options?.length;

  const isValid = organizationName && category && (!hasPrimaryUseCases || !!primaryUseCase);

  const nextTooltip = isValid ? '' : TOOLTIP_REQUIRED_FIELDS;

  const scrollToRef = useCallback(() => {
    containerRef?.current?.scrollTo(0, containerRef?.current?.scrollHeight);
  }, [containerRef]);

  const onOrganizationProvisioned = async () => {
    const redirectTo = USE_CASE_CATEGORIES[category as UseCaseCategoryValues]?.redirectTo;
    const useCaseCategory = category;
    const serviceUrl = config.servicesUrl[redirectTo] || '';

    const settings = {
      currentOrganization: organizationId,
      currentVault: '',
      organizationName: organizationName!,
    };

    try {
      await updateOrganization.mutateAsync({ organizationId, usecase: primaryUseCase });
      await enableControlService.mutateAsync(organizationId);
      await PersonalizationApi.updateUserProfile(userId!, { settings });
    } catch (err) {
    } finally {
      setIsProvisioning(false);
    }

    heapAnalytics.track(FLOW_COMPLETE_EVENT, {
      [FlowCompleteEvent.REDIRECT_TO]: redirectTo,
      [FlowCompleteEvent.USE_CASE_CATEGORY]: useCaseCategory,
      [FlowCompleteEvent.USE_CASE_DETAILS]: primaryUseCase,
    });

    gtm.pushEvent({
      event: 'pushEvent',
      eventAction: FLOW_COMPLETE_EVENT,
      eventCategory: useCaseCategory as string,
      eventLabel: primaryUseCase,
    });

    if (hubspotFormRef?.current?.submitForm) {
      hubspotFormRef?.current?.submitForm();
    }

    window.location.assign(serviceUrl);
  };

  useEffect(() => {
    if (organizationStatus?.provisioned) {
      setIsOrganizationProvisioned(true);

      onOrganizationProvisioned();
    }
  }, [organizationStatus]);

  useEffect(() => {
    const { email, sub } = AuthService.getParsedToken() || {};
    const formField = PERSONALIZATION_FORM_FIELDS.EMAIL;

    if (heapAnalytics.identify && email) {
      heapAnalytics.identify(email);
      setUserId(sub);

      setPersonalizationForm((prevValue) => ({
        ...prevValue,
        [formField]: email,
      }));
    }
  }, [heapAnalytics.identify]);

  useEffect(() => {
    if (hasPrimaryUseCases) {
      scrollToRef();
    }
  }, [hasPrimaryUseCases, scrollToRef]);

  const updateOrganizationProfile = useCallback((e) => {
    const {
      target: { value },
    } = e;

    setOrganizationName(value);
  }, []);

  const handleUseCaseCategory = useCallback(
    (category: UseCaseCategoryValues) => () => {
      setCategory(category);
      setPrimaryUseCase(category);
    },
    [],
  );

  const handleUseCase = useCallback((value: string) => {
    setPrimaryUseCase(value);
  }, []);

  const handleSubmit = async () => {
    if (isValid) {
      setIsProvisioning(true);

      createOrganization.mutate(
        { name: organizationName! },
        {
          onSuccess: ({ id }) => handleCreateOrganizationSuccess(id!),
          onError: () => handleCreateOrganizationError(),
        },
      );
    }
  };

  const handleCreateOrganizationSuccess = (orgId: string) => {
    const formFields = {
      [PERSONALIZATION_FORM_FIELDS.ORGANIZATION_ID]: orgId,
      [PERSONALIZATION_FORM_FIELDS.ORGANIZATION_NAME]: organizationName,
      [PERSONALIZATION_FORM_FIELDS.PRIMARY_USE_CASE]: primaryUseCase,
    };

    setOrganizationId(orgId!);
    setPersonalizationForm((prevValue) => ({
      ...prevValue,
      ...formFields,
    }));
  };

  const handleCreateOrganizationError = () => {
    setIsProvisioning(false);

    notification.error({
      message: 'Failed to create organization',
    });
  };

  return (
    <Fragment>
      <Card className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200">
        <div className="tw-flex tw-flex-col tw-h-full">
          <div ref={containerRef} className="tw-flex-1 tw-p-8 tw-overflow-auto">
            <h2 className="tw-header tw-text-base">Welcome to Very Good Security!</h2>

            <div className="tw-flex tw-justify-between tw-overflow-auto sm:tw-flex-col sm:tw-flex-col-reverse md:tw-flex-row">
              <div className="widget-content tw-flex-1">
                <div className="tw-text-body-b2 tw-mb-8">
                  Please provide a little information about your needs so that we can get started.
                  You can update this information later.
                </div>

                <section className="tw-mb-4">
                  <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
                    How should we refer to your organization?{' '}
                    <Popover content="The identifier used for your organization across VGS">
                      <Icon
                        className="tw-ml-2 tw-cursor-pointer"
                        type="info-circle"
                        theme="filled"
                      />
                    </Popover>
                  </div>
                  <Input value={organizationName} onChange={updateOrganizationProfile} />
                </section>

                <section className="tw-mb-8">
                  <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
                    What can we help you to achieve?{' '}
                    <Popover content="Please select the use case that most closely aligns to your needs">
                      <Icon
                        className="tw-ml-2 tw-cursor-pointer"
                        type="info-circle"
                        theme="filled"
                      />
                    </Popover>
                  </div>

                  <div className="tw-flex tw-flex-wrap tw-justify-between">
                    {Object.keys(USE_CASE_CATEGORY_DESCRIPTION).map((option: string) => {
                      const { label, description } = USE_CASE_CATEGORY_DESCRIPTION[
                        option as UseCaseCategoryValues
                      ];
                      const isSelected = category === option;

                      return (
                        <Tile
                          key={option}
                          className="flex-initial tw-my-3"
                          title={label}
                          active={isSelected}
                          onClick={handleUseCaseCategory(option as UseCaseCategoryValues)}
                        >
                          {description}
                        </Tile>
                      );
                    })}
                  </div>
                </section>

                {hasPrimaryUseCases && (
                  <section className="tw-mb-8">
                    <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
                      {USE_CASE_CATEGORIES[category!].optionLabel}{' '}
                      <Popover content="Please select the use case that most closely aligns to your needs">
                        <Icon
                          className="tw-ml-2 tw-cursor-pointer"
                          type="info-circle"
                          theme="filled"
                        />
                      </Popover>
                    </div>

                    <Select
                      className="tw-w-full"
                      placeholder="Please select an option"
                      onChange={handleUseCase}
                    >
                      {USE_CASE_CATEGORIES[category!].options.map(({ id, label }) => (
                        <Option key={id} value={id}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  </section>
                )}
              </div>

              <div className="tw-flex tw-items-start tw-ml-12 sm:tw-justify-center sm:tw-m-4">
                <img src="/images/vgs-console-overview.svg" alt="VGS Console" />
              </div>
            </div>
          </div>

          <div className="widget-actions tw-flex tw-items-center tw-justify-center tw-border-t tw-border-neutral-200">
            <Tooltip title={nextTooltip}>
              <span></span>
              <Button type="primary" onClick={handleSubmit} disabled={!isValid || isProvisioning}>
                Continue
              </Button>
            </Tooltip>
          </div>
        </div>
      </Card>
      <Modal visible={isProvisioning} centered closable={false} footer={null}>
        <h5 className="tw-header tw-mb-4">Setting up your organization in VGS</h5>

        <div className="tw-text-body-b3">
          We are configuring your organization based on your needs. This may take up to 10 seconds
          to complete.
        </div>
      </Modal>
      <HubspotPersonalizationForm ref={hubspotFormRef} formValues={personalizationForm} />
    </Fragment>
  );
};

export default WelcomeWidget;

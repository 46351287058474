import Keycloak, { KeycloakInitOptions, KeycloakTokenParsed } from 'keycloak-js';
import config from '@vgs-config';

const REFRESH_EXPIRE_IN = 30;

const kcInitOptions: KeycloakInitOptions = {
  checkLoginIframe: false,
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
};

export interface ParsedToken extends KeycloakTokenParsed {
  email: string;
  user_id: string;
}

const redirectUri = window.location.origin;

const kc = new Keycloak(config.keycloakConfig);
const kcLogin = () => kc.login({ redirectUri });
const getParsedToken = () => kc.tokenParsed as ParsedToken | undefined;
const kcLogout = () => kc.logout({ redirectUri });
const kcUpdateToken = () => kc.updateToken(REFRESH_EXPIRE_IN).catch(kcLogin);

const initKeycloak = (onAuthCallback: VoidFunction) => {
  kc.init(kcInitOptions).then((isAuthenticated: boolean) => {
    if (isAuthenticated) {
      onAuthCallback();
    } else {
      kcLogin();
    }
  });
};

const AuthService = {
  client: kc,
  kcLogin,
  kcLogout,
  kcUpdateToken,
  initKeycloak,
  getParsedToken,
};

export const getAccessToken = () => kc.token ?? '';

export default AuthService;

import React, { useCallback, useEffect, useState } from 'react';
import { Card, Tabs, notification } from '@vgs/elemente';
import { AuthService } from '@vgs-console/services';
import { useUpdateUserInfo, useUserInfo, useLogoutFromAllSessions } from '@vgs-console/hooks';
import {
  Loader,
  UserProfilePersonalSettingsForm,
  UserProfileSecuritySettings,
} from '@vgs-console/components';
import { UserProfile } from '@vgs-console/api';

const { TabPane } = Tabs;

const UserProfileSettings = () => {
  const [userProfile, setUserProfile] = useState<UserProfile>({} as UserProfile);
  const [kcUser] = useState(AuthService.getParsedToken());
  const userId = kcUser?.user_id;

  const { data: userInfo, isLoading } = useUserInfo(userId ?? '');
  const userInfoMutation = useUpdateUserInfo(userId ?? '');
  const logoutFromAllSessionsMutation = useLogoutFromAllSessions();

  useEffect(() => {
    const profile = (userInfo || {}) as UserProfile;

    setUserProfile(profile);
  }, [userInfo]);

  const handleSubmit = useCallback(
    (data) => {
      userInfoMutation.mutate(data, {
        onSuccess: (response) => {
          notification.success({
            message: 'User profile settings have been successfully updated',
          });

          setUserProfile(response);
        },
      });
    },
    [userInfoMutation],
  );

  const logoutFromAll = () => logoutFromAllSessionsMutation.mutate();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Tabs defaultActiveKey="personal">
        <TabPane tab="Personal" key="personal">
          <Card>
            <UserProfilePersonalSettingsForm userProfile={userProfile} onSubmit={handleSubmit} />
          </Card>
        </TabPane>
        <TabPane tab="Security" key="security">
          <Card>
            <UserProfileSecuritySettings
              otpEnabled={userProfile.otpEnabled}
              onChangeOTPState={handleSubmit}
              logout={logoutFromAll}
              isLoading={userInfoMutation.isLoading}
            />
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserProfileSettings;

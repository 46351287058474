import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { GuardedRoute, Header, NavigationSidebar } from '@vgs-console/components';
import { useAccountsApi } from '@vgs-console/hooks';
import { PATHS, ROUTES } from '@vgs-console/routes';
import '@vgs/elemente/dist/elemente.esm.css';
import './App.scss';

function App() {
  const { pathname } = useLocation();
  const [setupCompleted, setSetupCompleted] = useState(false);
  const { organizations } = useAccountsApi();
  const isDashboardMode = pathname !== PATHS.GETTING_STARTED && !!setupCompleted;

  useEffect(() => {
    setSetupCompleted(!!organizations.data?.length);
  }, [organizations.data?.length]);

  return (
    <div className="tw-flex tw-bg-neutral-100 tw-h-screen tw-overflow-hidden">
      {isDashboardMode && <Header sidebar />}

      {isDashboardMode && <NavigationSidebar />}

      <main
        className={cx('tw-overflow-auto tw-flex-1 tw-pb-8 tw-px-8', {
          'tw-pt-20': isDashboardMode,
          'tw-pt-8': !isDashboardMode,
        })}
      >
        <Switch>
          {ROUTES.map((route) => (
            <GuardedRoute key={route.path} {...route} />
          ))}
        </Switch>
      </main>
    </div>
  );
}

export default App;

import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Popover, Menu } from '@vgs/elemente';
import { useAccountsApi, useCurrentOrganization } from '@vgs-console/hooks';
import { PATHS } from '@vgs-console/routes';
import { Organization } from '@vgs-console/api';

const OrganizationSwitcher = () => {
  const history = useHistory();
  const { organizations } = useAccountsApi();
  const { organization: currentOrganization, switchOrganization } = useCurrentOrganization();
  const [isSwitcherShown, setIsSwitcherShown] = useState<boolean>(false);

  const goToSettings = useCallback(() => {
    history.push(PATHS.ORG_SETTINGS);
  }, []);

  const switchCurrentOrganization = useCallback(
    (organization: Organization) => () => {
      switchOrganization(organization);
      setIsSwitcherShown(false);
    },
    [],
  );

  const toggleSwitcher = useCallback(() => {
    setIsSwitcherShown((prevState) => !prevState);
  }, []);

  const content = (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-6 tw-border-b tw-border-neutral-200">
        <h2 className="tw-subhead">Your Organizations</h2>
        <Button type="link" className="tw-p-0" onClick={goToSettings}>
          Settings
        </Button>
      </div>

      <Menu className="tw-overflow-auto">
        {organizations.data?.map((organization) => {
          const { id, name } = organization;
          const isDisabled = id === currentOrganization?.id;

          return (
            <Menu.Item
              key={id}
              className={cx('vgs-organization-row', { disabled: isDisabled })}
              onClick={switchCurrentOrganization(organization)}
            >
              <div className="tw-flex tw-flex-col">
                <h2 className="vgs-organization-label">{name}</h2>
                <span className="tw-text-body-b2">{id}</span>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );

  if (!currentOrganization) {
    return null;
  }

  return (
    <Popover
      overlayClassName="vgs-organization-switcher"
      trigger="click"
      placement="bottomLeft"
      autoAdjustOverflow={false}
      content={content}
      visible={isSwitcherShown}
      onVisibleChange={toggleSwitcher}
    >
      <div className="tw-flex tw-items-center tw-text-body-b2 tw-cursor-pointer">
        <Icon className="tw-mr-2" type="apartment" />
        <div className="tw-truncate">{currentOrganization.name}</div>
        <Icon className="tw-ml-2" type="down" />
      </div>
    </Popover>
  );
};

export default OrganizationSwitcher;

import { map, prop } from 'ramda';
import config from '@vgs-config';
import { HttpClient } from '@vgs-console/services';
import { mapToCamelCase, mapToSnakeCase } from './mappers';

const CREATE_VAULT_HEADER = 'VGS-Enhanced-Onboarding';

interface SSOConfig {
  enabled: boolean;
  metadataUrl?: string;
  idpSignInOnly: boolean;
  idpAlias?: string;
}

export interface Organization {
  active: boolean;
  id?: string;
  identifier: string;
  internal_id: string;
  name: string;
  permissions: ['read', 'write', 'admin'];
  sso_saml_config: SSOConfig;
  state: 'ACTIVE' | 'PENDING_ACTIVATION' | 'INCOMPLETE';
}

export interface OrganizationStatus {
  id: string;
  provisioned: boolean;
}

interface OrganizationResponse {
  attributes: Organization | OrganizationStatus;
  id: string;
}

class AccountsApi {
  static BASE_URL = config.api.accountsUrl;

  private _config = {
    baseURL: AccountsApi.BASE_URL,
  };

  constructor() {
    this.getOrganizations = this.getOrganizations.bind(this);
  }

  get organizationsPath() {
    return '/organizations';
  }

  getOrganizationStatus(organizationId: string): Promise<OrganizationStatus> {
    const url = `${this.organizationsPath}/${organizationId}/status`;

    return HttpClient.get(url, this._config).then(({ data }) => {
      const normalized = mapToCamelCase(data) as OrganizationResponse;

      return {
        ...prop('attributes', normalized),
        id: normalized.id,
      } as OrganizationStatus;
    });
  }

  getOrganizations() {
    const url = `${this.organizationsPath}`;

    return HttpClient.get(url, this._config).then(({ data }) => {
      const normalized = mapToCamelCase(data) as Array<OrganizationResponse>;
      const withIds = (org: OrganizationResponse) => ({
        ...prop('attributes', org),
        id: org.id,
      });

      return map(withIds, normalized) as Organization[];
    });
  }

  createOrganization(attributes: any) {
    const url = `${this.organizationsPath}`;
    const payload = mapToSnakeCase({ data: { attributes, type: 'organizations' } });
    const headers = {
      [CREATE_VAULT_HEADER]: false,
    };

    return HttpClient.post(url, payload, { ...this._config, headers }).then(({ data }) => {
      const normalized = mapToCamelCase(data) as OrganizationResponse;

      return {
        ...prop('attributes', normalized),
        id: normalized.id,
      } as Organization;
    });
  }

  updateOrganizationProfile(id: string, attributes: any) {
    const url = `${this.organizationsPath}/${id}/profile`;
    const payload = mapToSnakeCase({ data: { id, attributes, type: 'organization-profile' } });

    return HttpClient.put(url, payload, this._config).then(({ data }) => {
      const normalized = mapToCamelCase(data) as OrganizationResponse;

      return {
        ...prop('attributes', normalized),
        id: normalized.id,
      } as Organization;
    });
  }
}

export default new AccountsApi();

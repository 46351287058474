import React, { useCallback } from 'react';
import { mapObjIndexed, pickBy } from 'ramda';
import { Button, Form, FormComponentProps, Icon, Input, Popover } from '@vgs/elemente';
import { UserProfile } from '@vgs-console/api';

interface UserProfilePersonalSettingsFormProps extends FormComponentProps {
  userProfile: UserProfile;
  onSubmit?: (userProfile: Partial<UserProfile>) => void;
}

const UserProfilePersonalSettingsForm: React.FC<UserProfilePersonalSettingsFormProps> = (props) => {
  const { form, userProfile, onSubmit } = props;

  const resetUserProfile = useCallback(() => {
    form.resetFields();
  }, [form, userProfile]);

  const submitUserProfile = useCallback(
    (e) => {
      e.preventDefault();

      form.validateFields((err, values) => {
        if (!err) {
          const isFieldChanged = (value: string, key: string) =>
            value !== userProfile[key as keyof UserProfile];
          const nextValues = pickBy(isFieldChanged, values);

          onSubmit && onSubmit(nextValues as Partial<UserProfile>);
        }
      });
    },
    [form, onSubmit],
  );

  return (
    <Form onSubmit={submitUserProfile}>
      <div className="tw-flex tw-mb-4">
        <div className="tw-flex-1 tw-mr-20">
          <Form.Item className="tw-mb-4">
            <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
              Full name{' '}
              <Popover content="Please enter your full name">
                <Icon className="tw-ml-2 tw-cursor-pointer" type="info-circle" theme="filled" />
              </Popover>
            </div>

            {form.getFieldDecorator('firstName', {
              initialValue: userProfile.firstName,
              rules: [{ required: true, message: 'Please enter your full name' }],
            })(<Input />)}
          </Form.Item>

          <Form.Item className="tw-mb-4">
            <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
              Email address{' '}
              <Popover content="Please enter your email address">
                <Icon className="tw-ml-2 tw-cursor-pointer" type="info-circle" theme="filled" />
              </Popover>
            </div>

            <Input value={userProfile.email} disabled />
          </Form.Item>
        </div>

        <div className="tw-flex-1">
          <Form.Item className="tw-mb-4">
            <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
              Phone number{' '}
              <Popover content="Please enter your phone number">
                <Icon className="tw-ml-2 tw-cursor-pointer" type="info-circle" theme="filled" />
              </Popover>
            </div>

            {form.getFieldDecorator('phone', {
              initialValue: userProfile.phone,
              rules: [{ required: true, message: 'Please enter your phone number' }],
            })(<Input prefix="+" />)}
          </Form.Item>
        </div>
      </div>

      <Form.Item className="tw-flex tw-mb-0">
        <Button type="primary" className="tw-mr-4" htmlType="submit">
          Save Changes
        </Button>
        <Button type="ghost_gray" onClick={resetUserProfile}>
          Revert Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapPropsToFields = (props: UserProfilePersonalSettingsFormProps) => {
  const { userProfile } = props;

  return mapObjIndexed((value) => Form.createFormField({ value }, userProfile));
};

export default Form.create<UserProfilePersonalSettingsFormProps>({
  name: 'user-profile-settings',
  mapPropsToFields,
})(UserProfilePersonalSettingsForm);

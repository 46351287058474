import React, { forwardRef, useMemo } from 'react';
import { FeedbackForm, HubspotPersonalizationFormRef } from '@vgs/elemente';
import config from '@vgs-config';

import {
  PERSONALIZATION_FORM_CONFIG,
  PersonalizationFormFields,
  PersonalizationFormValues,
} from './hubspot-forms';

interface HubspotPersonalizationFormProps {
  formValues: PersonalizationFormValues;
}

const HubspotPersonalizationForm = forwardRef<
  HubspotPersonalizationFormRef,
  HubspotPersonalizationFormProps
>((props, ref) => {
  const { formValues } = props;
  const hubspotConfig = config?.hubspotForms?.personalizationForm;

  const formConfig = useMemo(() => {
    const values = Object.keys(PERSONALIZATION_FORM_CONFIG).reduce((form, key) => {
      const field = key as PersonalizationFormFields;
      form[field].value = formValues[field] || '';

      return form;
    }, PERSONALIZATION_FORM_CONFIG);

    return values;
  }, [formValues]);

  return (
    <FeedbackForm
      ref={ref}
      className="tw-invisible tw-absolute tw-m-0"
      formConfig={formConfig}
      hubspotConfig={hubspotConfig}
    />
  );
});

export default HubspotPersonalizationForm;

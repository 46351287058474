import { isNil } from 'ramda';
import { AccountsApi } from '@vgs-console/api';
import { AuthService, getAccessToken } from '@vgs-console/services';
import { PATHS } from '@vgs-console/routes';
import browserHistory from './browser-history';
import queryClient, { QueryType } from './query-client';

export const authGuard = () => {
  const isAuthenticated = !!getAccessToken();

  if (!isAuthenticated) {
    AuthService.kcLogin();
  }

  return Promise.resolve(isAuthenticated);
};

export const setupCompleteGuard = async () => {
  const organizations = queryClient.getQueryState<any[]>(QueryType.ORGANIZATIONS)?.data;

  let isSetupCompleted = !!organizations?.length;

  if (isNil(organizations)) {
    const organizations = await queryClient.fetchQuery(
      QueryType.ORGANIZATIONS,
      AccountsApi.getOrganizations,
    );

    isSetupCompleted = !!organizations.length;
  }

  if (!isSetupCompleted) {
    setTimeout(() => browserHistory.push(PATHS.GETTING_STARTED), 0);
  }

  return isSetupCompleted;
};

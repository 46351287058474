export const PERSONALIZATION_FORM_FIELDS = {
  PRIMARY_USE_CASE: 'useCase',
  EMAIL: 'email',
  ORGANIZATION_NAME: 'organizationName',
  ORGANIZATION_ID: 'organizationId',
};

type GetValues<T> = T extends { [K in keyof T]: infer R } ? R : never;

export type PersonalizationFormFields = GetValues<typeof PERSONALIZATION_FORM_FIELDS>;

export type PersonalizationFormValues = {
  [key in PersonalizationFormFields]?: string | null;
};

export type PersonalizationForm = {
  [key in PersonalizationFormFields]: {
    fieldName: string;
    value?: string | null;
  };
};

export const PERSONALIZATION_FORM_CONFIG: PersonalizationForm = {
  [PERSONALIZATION_FORM_FIELDS.PRIMARY_USE_CASE]: {
    fieldName: 'what_is_your_primary_use_case_',
  },
  [PERSONALIZATION_FORM_FIELDS.EMAIL]: {
    fieldName: 'email',
  },
  [PERSONALIZATION_FORM_FIELDS.ORGANIZATION_NAME]: {
    fieldName: 'vgs_org_name',
  },
  [PERSONALIZATION_FORM_FIELDS.ORGANIZATION_ID]: {
    fieldName: 'vgs_org_id',
  },
};

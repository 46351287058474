import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HeapAnalyticsProvider, GoogleTagManagerProvider } from '@vgs/elemente';
import config from '@vgs-config';
import ErrorBoundary from '@vgs-console/ErrorBoundary';
import AuthService from '@vgs-console/services/AuthService';
import { browserHistory, queryClient } from '@vgs-console/utils';
import { AuthProvider, CurrentOrganizationProvider } from '@vgs-console/hooks';

const renderer = () =>
  ReactDOM.render(
    <ErrorBoundary>
      <AuthProvider>
        <Router history={browserHistory}>
          <HeapAnalyticsProvider heapTrackingId={config.heapTrackingId}>
            <GoogleTagManagerProvider gtmConfig={config.googleTagManagerConfig}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />

                <CurrentOrganizationProvider>
                  <App />
                </CurrentOrganizationProvider>
              </QueryClientProvider>
            </GoogleTagManagerProvider>
          </HeapAnalyticsProvider>
        </Router>
      </AuthProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );

AuthService.initKeycloak(renderer);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import config from '@vgs-config';
import { AuthService, HttpClient } from '@vgs-console/services';
import { mapToCamelCase, mapToSnakeCase } from './mappers';

export interface UserProfile {
  createdAt: string;
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  otpEnabled: boolean;
}

class IamApi {
  static BASE_URL = config.api.iamUrl;

  private _config = {
    baseURL: IamApi.BASE_URL,
  };

  get usersPath() {
    return '/users';
  }

  get sessionsPath() {
    return '/sessions';
  }

  getUserById(userId: string): Promise<UserProfile> {
    const url = `${this.usersPath}/${userId}`;

    return HttpClient.get(url, this._config).then((data) => {
      return mapToCamelCase(data) as UserProfile;
    });
  }

  updateUserProfile(userId: string, data: Partial<UserProfile>): Promise<UserProfile> {
    const url = `${this.usersPath}/${userId}`;
    const payload = mapToSnakeCase(data);

    return HttpClient.patch(url, payload, this._config).then((data) => {
      return mapToCamelCase(data) as UserProfile;
    });
  }

  logoutAll() {
    const url = `${this.sessionsPath}`;

    return HttpClient.delete(url, this._config).then(() => AuthService.kcLogout());
  }
}

export default new IamApi();

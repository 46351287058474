import React from 'react';
import { Button, Icon, Popover } from '@vgs/elemente';
import { UserProfile } from '@vgs-console/api';
import { Link } from 'react-router-dom';
import AuthService from '@vgs-console/services/AuthService';

interface UserProfileSecuritySettingsProps {
  otpEnabled: boolean;
  onChangeOTPState: (userProfile: Partial<UserProfile>) => void;
  logout: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isLoading: boolean;
}

const UserProfileSecuritySettings: React.FC<UserProfileSecuritySettingsProps> = (props) => {
  const { otpEnabled, onChangeOTPState, logout, isLoading } = props;

  const setOTPState = () => onChangeOTPState({ otpEnabled: !otpEnabled });

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
          Two-factor authenticator
          <Popover content="Two-factor authenticator">
            <Icon className="tw-ml-2 tw-cursor-pointer" type="info-circle" theme="filled" />
          </Popover>
        </div>
        <Button type="ghost_gray" onClick={setOTPState} disabled={isLoading}>
          {`${otpEnabled ? 'Disable' : 'Enable'} Two-Factor`}
        </Button>
      </div>
      <p className="tw-text-body-b3">
        {otpEnabled ? (
          <>
            <p className="tw-mb-0">
              Enabled. On the next dashboard login, a prompt for configuring OTP will be displayed.
            </p>
            <Link to="/" onClick={AuthService.kcLogout}>
              Log out now
            </Link>
          </>
        ) : (
          'Not enabled'
        )}
      </p>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
          Account activity
          <Popover content="Account activity">
            <Icon className="tw-ml-2 tw-cursor-pointer" type="info-circle" theme="filled" />
          </Popover>
        </div>
        <Button type="ghost_gray" onClick={logout}>
          Log out of all Sessions
        </Button>
      </div>
      <p className="tw-text-body-b3">
        You can terminate all active sessions, including this session.
      </p>
    </div>
  );
};

export default UserProfileSecuritySettings;

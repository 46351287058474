export const CONTROL_SERVICE = 'control';
export const VAULT_SERVICE = 'vault';

export enum UseCaseCategory {
  CARD_ISSUING = 'card-issuing',
  COMPLIANCE = 'compliance',
  DATA_SECURITY = 'data-security',
  OTHER = 'other',
  PAYMENT_OPTIMIZATION = 'payment-optimization',
  SECURITY_MONITORING = 'security-monitoring',
}

export enum PrimaryUseCase {
  FUND_DISBURSING = 'fund-disbursing',
  GDPR = 'gdpr',
  HIPAA = 'hipaa',
  IDENTITY_VERIFICATION = 'identity-verification',
  ISO_27001 = 'iso_27001',
  MULTIPLEXING = 'multiplexing',
  PAYMENT_MERCHANT = 'payment-merchant',
  PAYMENT_FACILITATION = 'payment-facilitation',
  PCI = 'pci',
  PHI_ALIASING = 'phi-aliasing',
  PII_ALIASING = 'pii-aliasing',
  PII_ALIASING_MARKETING = 'pii-aliasing-marketing',
  SOC_2 = 'soc_2',
  STORING_CREDENTIALS = 'storing-credentials',
  TOKENIZING = 'tokenizing',
}

export type UseCaseCategoryTraits = keyof typeof UseCaseCategory;
export type UseCaseCategoryValues = keyof {
  [M in keyof typeof UseCaseCategory as `${typeof UseCaseCategory[M]}`]: M;
};

type UseCaseCategories = {
  [key in UseCaseCategoryValues]: {
    optionLabel?: string;
    options: Array<{ id: string; label: string }>;
    redirectTo: typeof CONTROL_SERVICE | typeof VAULT_SERVICE;
  };
};

export const USE_CASE_CATEGORIES: UseCaseCategories = {
  [UseCaseCategory.COMPLIANCE]: {
    optionLabel: 'What type of compliance do you need?',
    options: [
      { id: PrimaryUseCase.SOC_2, label: 'SOC 2' },
      { id: PrimaryUseCase.PCI, label: 'PCI' },
      { id: PrimaryUseCase.ISO_27001, label: 'ISO 27001' },
      { id: PrimaryUseCase.HIPAA, label: 'HIPAA' },
      { id: PrimaryUseCase.GDPR, label: 'GDPR' },
    ],
    redirectTo: CONTROL_SERVICE,
  },
  [UseCaseCategory.SECURITY_MONITORING]: {
    options: [],
    redirectTo: CONTROL_SERVICE,
  },
  [UseCaseCategory.PAYMENT_OPTIMIZATION]: {
    optionLabel: 'What type of payment optimization do you need?',
    options: [
      { id: PrimaryUseCase.PAYMENT_MERCHANT, label: 'Payment Processing - Merchant' },
      { id: PrimaryUseCase.PAYMENT_FACILITATION, label: 'Payment Processing - Pay Facilitation' },
      { id: PrimaryUseCase.MULTIPLEXING, label: 'Payments Optimization/Multiplexing' },
    ],
    redirectTo: VAULT_SERVICE,
  },
  [UseCaseCategory.CARD_ISSUING]: {
    options: [],
    redirectTo: VAULT_SERVICE,
  },
  [UseCaseCategory.DATA_SECURITY]: {
    optionLabel: 'What type of data security do you need?',
    options: [
      { id: PrimaryUseCase.FUND_DISBURSING, label: 'Fund Disbursing' },
      { id: PrimaryUseCase.IDENTITY_VERIFICATION, label: 'Identity Verification' },
      { id: PrimaryUseCase.PHI_ALIASING, label: 'PHI Aliasing' },
      { id: PrimaryUseCase.PII_ALIASING, label: 'PII Aliasing' },
      { id: PrimaryUseCase.PII_ALIASING_MARKETING, label: 'PII Aliasing - Marketing' },
      { id: PrimaryUseCase.STORING_CREDENTIALS, label: 'Storing Credentials' },
      { id: PrimaryUseCase.TOKENIZING, label: 'Tokenizing' },
    ],
    redirectTo: VAULT_SERVICE,
  },
  [UseCaseCategory.OTHER]: {
    options: [],
    redirectTo: VAULT_SERVICE,
  },
};

import React, { createContext, useContext, useState } from 'react';
import { AuthService, ParsedToken } from '@vgs-console/services';

interface AuthProps {
  email: string;
  userId: string;
  sub?: string;
}

const AuthContext = createContext({} as AuthProps);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<{}> = (props) => {
  const { children } = props;

  const [auth] = useState(() => AuthService.client);

  const config = {
    email: (auth?.tokenParsed as ParsedToken)?.email,
    sub: (auth?.tokenParsed as ParsedToken)?.sub,
    userId: (auth?.tokenParsed as ParsedToken)?.user_id,
  };

  return <AuthContext.Provider value={config}>{children}</AuthContext.Provider>;
};

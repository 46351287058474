import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import config from '@vgs-config';
import { Icon, Menu, Popover } from '@vgs/elemente';
import { OrganizationSwitcher } from '@vgs-console/components';
import { PATHS } from '@vgs-console/routes';
import AuthService from '@vgs-console/services/AuthService';

const Header: React.FC<{ sidebar?: boolean }> = (props) => {
  const { sidebar } = props;
  const { pathname } = useLocation();
  const [kcUser] = useState(AuthService.getParsedToken());
  const { docsUrl, support } = config;

  const username = kcUser?.email;

  const shouldRenderOrganizationSwitcher = [PATHS.ORG_SETTINGS, PATHS.USER_MANAGEMENT].includes(
    pathname,
  );

  const supportInfo = (
    <div className="tw-text-body-b2">
      Email: <a href={`mailto:${support.email}`}>{support.email}</a>
      <br />
      Online:{' '}
      <a href={support.url} target="_blank" rel="noopener noreferrer">
        {support.url}
      </a>
    </div>
  );

  const userInfo = (
    <Menu mode="inline">
      <Menu.Item key="logout" onClick={AuthService.kcLogout}>
        <span className="tw-text-body-b2">Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className={cx('header', { 'with-sidebar': sidebar })}>
      <div className="tw-flex tw-h-full tw-items-center tw-justify-between tw-px-8">
        <div className="tw-w-1/6">
          <h1
            className={cx({
              'tw-flex tw-items-center': !sidebar,
              'tw-hidden': sidebar,
            })}
          >
            <a href="/" className="tw-flex tw-items-center tw-no-underline hover:tw-no-underline">
              <img src="/images/vgs-logo.svg" alt="Very Good Security" width="32" height="32" />
              <div className="tw-uppercase tw-font-bold tw-ml-2 tw-text-lg tw-text-neutral-700">
                Console
              </div>
            </a>
          </h1>

          {shouldRenderOrganizationSwitcher && <OrganizationSwitcher />}
        </div>

        <nav className="tw-flex tw-h-full">
          <ul className="tw-flex tw-items-center tw-m-0">
            <li className="tw-flex tw-h-full tw-items-center tw-ml-5">
              <Popover content={supportInfo} trigger="click" placement="bottomLeft">
                <div className="tw-text-body-b2 tw-cursor-pointer">Support</div>
              </Popover>
            </li>
            <li className="tw-flex tw-h-full tw-items-center tw-ml-5">
              <a
                href={docsUrl}
                className="tw-text-body-b2 tw-no-underline hover:tw-text-current hover:tw-no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </li>
            <li className="user-profile">
              <Popover
                overlayClassName="user-profile-dropdown"
                content={userInfo}
                trigger="click"
                placement="bottomRight"
              >
                <div className="user-profile-info tw-text-body-b2">
                  <Icon className="tw-mr-1" type="user" />
                  <div className="tw-truncate">{username}</div>
                  <Icon className="tw-ml-1" type="down" />
                </div>
              </Popover>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

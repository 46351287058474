import React from 'react';
import { UseCaseCategory, UseCaseCategoryValues } from './use-case-categories';

type CategoryDescription = {
  [key in UseCaseCategoryValues]: {
    label: string;
    description: React.ReactNode;
  };
};

export const USE_CASE_CATEGORY_DESCRIPTION: CategoryDescription = {
  [UseCaseCategory.COMPLIANCE]: {
    label: 'Compliance & Monitoring',
    description: 'SOC 2, PCI, ISO 27001, HIPAA, GDPR, and more.',
  },
  [UseCaseCategory.SECURITY_MONITORING]: {
    label: 'Security Monitoring',
    description: 'Monitor your infrastructure for continuous compliance.',
  },
  [UseCaseCategory.PAYMENT_OPTIMIZATION]: {
    label: 'Payment Optimization',
    description: 'Optimize payments from your customers with secure data.',
  },
  [UseCaseCategory.CARD_ISSUING]: {
    label: 'Card Issuing',
    description: 'Securely issue cards to your customers.',
  },
  [UseCaseCategory.DATA_SECURITY]: {
    label: 'Data Security',
    description: 'Securely store and retrieve your sensitive data.',
  },
  [UseCaseCategory.OTHER]: {
    label: 'Other',
    description: 'Additional needs not covered above.',
  },
};

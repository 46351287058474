import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { ApplicationSwitcher, Icon } from '@vgs/elemente';
import config from '@vgs-config';
import { PATHS } from '@vgs-console/routes';

const appSwitcherAlignConfig = {
  offset: [-42, 0] as [React.ReactText, React.ReactText],
};

const navLinks = [
  { label: 'Dashboard', to: PATHS.DASHBOARD },
  { label: 'User Profile Settings', to: PATHS.USER_PROFILE },
  { label: 'Organization Settings', to: PATHS.ORG_SETTINGS },
  { label: 'User Management', to: PATHS.USER_MANAGEMENT },
];

const NavigationSidebar = () => {
  const { pathname } = useLocation();

  return (
    <nav className="nav-sidebar" aria-label="Main navigation">
      <h1 className="nav-sidebar-header">
        <a href="/" className="tw-flex tw-items-center no-underline hover:tw-no-underline">
          <img src="/images/vgs-logo.svg" alt="Very Good Security" width="32" height="32" />
        </a>

        <ApplicationSwitcher
          variant="console"
          urls={config.servicesUrl}
          placement="bottomLeft"
          autoAdjustOverflow={false}
          align={appSwitcherAlignConfig}
        >
          <div className="tw-flex tw-items-center tw-uppercase tw-font-bold tw-ml-2 tw-text-lg tw-text-neutral-700 tw-cursor-pointer">
            Console
            <Icon className="tw-ml-1" type="down" />
          </div>
        </ApplicationSwitcher>
      </h1>

      <ul className="tw-py-6">
        {navLinks.map(({ label, to }) => {
          const isActive = pathname === to;

          return (
            <li key={label} className={cx('nav-sidebar-link', { active: isActive })}>
              <NavLink
                to={to}
                className={cx('nav-sidebar-link-item', {
                  'tw-text-body-b3': !isActive,
                  'tw-text-body-bs3': isActive,
                })}
              >
                {label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavigationSidebar;

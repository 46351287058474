import React from 'react';
import { Route } from 'react-router-dom';
import { useGuard } from '@vgs-console/hooks';
import { Loader } from '@vgs-console/components';

const GuardedRoute = (props: any) => {
  const { canActivate, ...routeConfig } = props;
  const { canActivate: shouldRender, pending } = useGuard(canActivate);

  if (pending) {
    return <Loader />;
  }

  return shouldRender ? <Route {...routeConfig} /> : null;
};

export default GuardedRoute;

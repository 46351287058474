import { useEffect, useState } from 'react';
import { is } from 'ramda';
import { Guard } from '@vgs-console/routes';

export const useGuard = (guards: Guard[]) => {
  const [canActivate, setCanActivate] = useState(false);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const verifyPermissions = async () => {
      try {
        await Promise.all(
          guards.map(async (checkGuard, index) => {
            const hasPermissions = await checkGuard();

            if (!hasPermissions) {
              throw new Error('Forbidden');
            }

            return hasPermissions;
          }),
        );

        setCanActivate(true);
      } catch (err) {
        setCanActivate(false);
      }
    };

    if (is(Array, guards)) {
      setPending(true);

      verifyPermissions().finally(() => {
        setPending(false);
      });
    }
  }, [guards]);

  return { canActivate, pending };
};

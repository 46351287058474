import { FC } from 'react';
import { ReactComponent as ControlIcon } from '@vgs/elemente/dist/assets/icons/control-service.svg';
import { ReactComponent as VaultIcon } from '@vgs/elemente/dist/assets/icons/vault-service.svg';

type GetValues<T> = T extends { [K in keyof T]: infer R } ? R : never;

export const SERVICES = {
  CONTROL: 'control',
  VAULT: 'vault',
} as const;

export type AvailableService = GetValues<typeof SERVICES>;

interface ServiceDescription {
  label: string;
  description: string;
  icon: FC<{ className?: string }>;
}

export const AVAILABLE_SERVICES_DESCRIPTION: {
  [key in AvailableService]: ServiceDescription;
} = {
  [SERVICES.CONTROL]: {
    label: 'Control',
    description: 'Automate your compliance needs',
    icon: ControlIcon,
  },
  [SERVICES.VAULT]: {
    label: 'Vault',
    description: 'Securely store your data',
    icon: VaultIcon,
  },
};

import { useQuery, useMutation } from 'react-query';
import { IamApi, UserProfile } from '@vgs-console/api';

export const useUserInfo = (userId: string) => {
  return useQuery<UserProfile>(['userProfile', userId], () => IamApi.getUserById(userId));
};

export const useUpdateUserInfo = (userId: string) => {
  return useMutation(['userProfile', userId], (data: Partial<UserProfile>) =>
    IamApi.updateUserProfile(userId, data),
  );
};

export const useLogoutFromAllSessions = () => {
  return useMutation(['userProfile'], () => IamApi.logoutAll());
};

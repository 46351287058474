import { ReactNode } from 'react';
import { RouteProps } from 'react-router-dom';
import {
  DashboardPage,
  GettingStartedPage,
  OrganizationSettingsPage,
  UserManagementPage,
  UserProfileSettingsPage,
} from '@vgs-console/pages';
import { authGuard, setupCompleteGuard } from '@vgs-console/utils';

export type Guard = (redirectTo?: (path: string) => void) => Promise<boolean>;

type RouteConfig = RouteProps & {
  canActivate?: Array<Guard>;
  loading?: ReactNode;
};

export const PATHS = {
  DASHBOARD: '/',
  GETTING_STARTED: '/getting-started',
  ORG_SETTINGS: '/organization-settings',
  USER_MANAGEMENT: '/user-management',
  USER_PROFILE: '/user-profile',
};

export const ROUTES: RouteConfig[] = [
  {
    path: PATHS.DASHBOARD,
    component: DashboardPage,
    canActivate: [authGuard, setupCompleteGuard],
    exact: true,
  },
  {
    path: PATHS.GETTING_STARTED,
    component: GettingStartedPage,
    canActivate: [authGuard],
    exact: true,
  },
  {
    path: PATHS.ORG_SETTINGS,
    component: OrganizationSettingsPage,
    canActivate: [authGuard, setupCompleteGuard],
    exact: true,
  },
  {
    path: PATHS.USER_MANAGEMENT,
    component: UserManagementPage,
    canActivate: [authGuard, setupCompleteGuard],
    exact: true,
  },
  {
    path: PATHS.USER_PROFILE,
    component: UserProfileSettingsPage,
    canActivate: [authGuard, setupCompleteGuard],
    exact: true,
  },
];

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { path, find, propEq, head } from 'ramda';
import { useAccountsApi, useAuth, usePersonalizationApi } from '@vgs-console/hooks';
import { Organization } from '@vgs-console/api';

const DEFAULT_ORGANIZATION = null;

const ORGANIZATION_PATH = 'data.settings.currentOrganization';

interface CurrentOrganizationProps {
  organization?: Organization | null;
  switchOrganization: (organization: Organization) => void;
}

const CurrentOrganizationContext = createContext<CurrentOrganizationProps>({
  organization: DEFAULT_ORGANIZATION,
  switchOrganization: () => {},
});

export const useCurrentOrganization = () => {
  return useContext(CurrentOrganizationContext);
};

export const CurrentOrganizationProvider: React.FC<{}> = (props) => {
  const { children } = props;

  const { sub } = useAuth();

  const [organization, setOrganization] = useState<Organization | null>();
  const { userProfile, updateUserProfile } = usePersonalizationApi(sub!);
  const { organizations } = useAccountsApi();

  useEffect(() => {
    if (userProfile.data && organizations.data?.length) {
      const currentOrganizationId = path(ORGANIZATION_PATH.split('.'), userProfile) as string;

      const currentOrganization =
        find(propEq('id', currentOrganizationId))(
          organizations.data as Array<Required<Organization>>,
        ) || head(organizations.data);

      setOrganization(currentOrganization as Organization);
    }
  }, [userProfile.data, organizations.data]);

  const switchOrganization = useCallback((organization: Organization) => {
    const settings = {
      currentOrganization: organization?.id!,
      currentVault: '',
      organizationName: organization?.name,
    };

    setOrganization(organization);

    updateUserProfile.mutate({ userId: sub!, settings });
  }, []);

  const config = {
    organization,
    switchOrganization,
  };

  return (
    <CurrentOrganizationContext.Provider value={config}>
      {children}
    </CurrentOrganizationContext.Provider>
  );
};

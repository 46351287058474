import config from '@vgs-config';
import { HttpClient } from '@vgs-console/services';

class ControlApi {
  static BASE_URL = config.api.controlUrl;

  private _config = {
    baseURL: ControlApi.BASE_URL,
  };

  constructor() {
    this.createOrganization = this.createOrganization.bind(this);
  }

  get organizationsPath() {
    return '/organizations';
  }

  createOrganization(organizationId: any) {
    const url = `${this.organizationsPath}/create`;
    const payload = {
      oid: organizationId,
    };

    return HttpClient.post(url, payload, { ...this._config });
  }
}

export default new ControlApi();

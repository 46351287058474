import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Card, Button } from '@vgs/elemente';
import config from '@vgs-config';
import { useAccountsApi } from '@vgs-console/hooks';
import { Access, Loader, Permission } from '@vgs-console/components';
import { PATHS } from '@vgs-console/routes';

import { AVAILABLE_SERVICES_DESCRIPTION, AvailableService } from './available-services';

const Dashboard = () => {
  const history = useHistory();
  const { organizations } = useAccountsApi();

  const goToProduct = (redirectTo: AvailableService) => () => {
    const serviceUrl = config.servicesUrl[redirectTo] || '';

    window.location.assign(serviceUrl);
  };

  const goToOrgSettings = (organizationId: string) => () => {
    history.push(PATHS.ORG_SETTINGS);
  };

  const goToUserSettings = (organizationId: string) => () => {
    history.push(PATHS.USER_MANAGEMENT);
  };

  const NoPermissionWarning = () => (
    <div className="tw-text-body-b2">You lack permissions to manage this organization</div>
  );

  return (
    <div>
      <Card className="vgs-widget">
        <div className="tw-p-6 tw-border-b tw-border-neutral-200">
          <h2 className="tw-header">Applications</h2>
        </div>
        <div className="tw-flex tw-flex-wrap tw-justify-between tw-p-6 tw--m-4">
          {Object.keys(AVAILABLE_SERVICES_DESCRIPTION).map((service) => {
            const availableService = service as AvailableService;
            const { label, description, icon: Icon } =
              AVAILABLE_SERVICES_DESCRIPTION[availableService] || {};

            return (
              <Card
                key={availableService}
                className="vgs-product-tile"
                onClick={goToProduct(availableService)}
              >
                <div className="tw-flex tw-items-center">
                  <Icon className="vgs-product-icon" />

                  <div className="tw-flex tw-flex-col">
                    <h2 className="tw-subhead tw-leading-normal tw-mb-2">{label}</h2>
                    <span className="tw-text-body-b2">{description}</span>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </Card>

      <Card className="vgs-widget">
        <div className="tw-p-6 tw-border-b tw-border-neutral-200">
          <h2 className="tw-header">Organizations</h2>
        </div>

        {organizations.isLoading && <Loader className="tw-p-6" />}

        <div className="vgs-organizations-list">
          {organizations.data?.map((organization) => {
            const { id, name, permissions } = organization;

            return (
              <div key={id} className="vgs-organization-row">
                <h2 className="tw-subhead">{name}</h2>

                <div>
                  <Permission.Admin
                    config={permissions as Access[]}
                    fallback={<NoPermissionWarning />}
                  >
                    <Button type="ghost_gray" className="tw-mr-4" onClick={goToOrgSettings(id!)}>
                      Settings
                    </Button>
                    <Button type="ghost_gray" onClick={goToUserSettings(id!)}>
                      Manage Users
                    </Button>
                  </Permission.Admin>
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;

import { useMutation, useQuery } from 'react-query';
import { AccountsApi, Organization } from '@vgs-console/api';

export const useAccountsApi = () => {
  const createOrganization = useMutation((data: { name: string }) =>
    AccountsApi.createOrganization(data),
  );

  const updateOrganization = useMutation((data: { organizationId: string; usecase: string }) => {
    const { organizationId, ...payload } = data;

    return AccountsApi.updateOrganizationProfile(organizationId, payload);
  });

  const organizations = useQuery<Organization[]>('organizations', AccountsApi.getOrganizations);

  return {
    createOrganization,
    organizations,
    updateOrganization,
  };
};

export const useOrganizationStatus = (orgId: string, options?: any) => {
  return useQuery(
    ['organizations', orgId, 'status'],
    () => AccountsApi.getOrganizationStatus(orgId),
    options,
  );
};

import { QueryClient } from 'react-query';

export enum QueryType {
  ORGANIZATIONS = 'organizations',
}

export default new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
